import $ from "jquery";
// import Swiper JS
import Swiper from 'swiper';
window.$ = $;
/*===================================================
Project: archito
Auther: Amin-Themes
Version:    1.1
Last change:   2 Novmber 2020
Template Description:    Architecure & Enterior design  HTML5 Template 
====================================================*/

//GLOBAL VARIBALES
var
    //selector vars
    main_window = $(window),
    root = $('html, body'),
    bdyOnePage = $('body.landing-page-demo '),
    pageHeader = $('#page-header'),
    navMain = $('nav.main-navbar'),
    navMenuWraper = $('.navbar-menu-wraper'),
    hasSubMenu = $(".menu-item-has-children"),
    onePage_navLink = $('.landing-page-demo .main-navbar .nav-link, .landing-page-demo .goto-link'),
    pageHero = $('#page-hero'),
    backToTopButton = $('.back-to-top'),
    heroVegasSlider = $(".page-hero.hero-vegas-slider"),
    heroSwiperSlider = ".hero-swiper-slider .swiper-container",
    textInput = $("form.main-form .text-input"),
    tabLink = $(".ma-tabs .tabs-links .tab-link"),
    togglerLink = $(".ma-tabs .toggler-links .toggler"),
    portfolioGroup = $('.portfolio .portfolio-group'),

    // Measurements vars
    navMainHeight = navMain.innerHeight(),
    pageHeroHeight = pageHero.innerHeight(),

    //class Names Strings vars

    hdrStandOut = "header-stand-out",
    inputHasText = "has-text",

    // condetionals vars
    counterShowsUp = false;




$(function () {
     
    "use strict"
    /*-----------------  START GENERAL FUNCTIONS  -----------------*/

    // function to open/close navbar search box
    $('.header-search-btn').on("click", function () {
        $(".header-search-box").addClass('show');

        setTimeout(function () {
            $(".search-input").focus()
        }, 1000);
    })

    $('.header-search-box .close-search').on("click", function () {
        $(".header-search-box").removeClass('show');
    })



    function adjust_tabLink_B_line() {
        // to Move the tab-link bottom line  
        if ($(".ma-tabs .tabs-links-list").length) {
            var pageDir = $("body").css("direction");

            var $line = tabLink.parent('.tabs-links-list').find(".b-line");
            var activTabLink = tabLink.parent('.tabs-links-list').children(".active");
            var eleParentWidth = activTabLink.parent('.tabs-links-list').innerWidth();
            var eleWidth = activTabLink.innerWidth();
            var eleLeft = activTabLink.position().left;
            var eleRight = eleParentWidth - (eleLeft + eleWidth);

            if (pageDir === "ltr") {
                $line.css({
                    "left": eleLeft + 'px',
                    "width": eleWidth + 'px',
                });
            } else
                $line.css({
                    "right": eleRight + 'px',
                    "width": eleWidth + 'px',
                });
        }

    }

    
    /*-----------------  END GENERAL FUNCTIONS  -----------------*/





    /*----------------- Start Calling global function -----------------*/

    //to adjust tabs links  underline 
    adjust_tabLink_B_line();



    /*----------------- End Calling global function -----------------*/


    // to remove class from navbar if the page refreshed and the scrolltop of the window > 50px
    if (main_window.scrollTop() > 100) {
        $(pageHeader).toggleClass(hdrStandOut)
        $(backToTopButton).toggleClass("show")
    }




    /* ----------------- End page loading Actions * ----------------- */


    /* ----------------- Start onClick Actions * ----------------- */

    //  Start Smooth Scrolling To page Sections
    $(onePage_navLink).on('click', function (e) {
        var link = $(this).attr('href');
        var currentMainNavHeight = navMain.innerHeight();

        if (link.charAt(0) === '#') {
            e.preventDefault();
            var target = this.hash;
            $(root).animate({
                scrollTop: $(target).offset().top - currentMainNavHeight + 1
            }, 500);
        }

    });

    //End Smooth Scrolling To page Sections

    $(".navbar-nav").on("click", function (e) {
        e.stopPropagation()
    })

    //  open and close menu btn
    $('.menu-toggler-btn, .navbar-menu-wraper ').on('click', function () {
        $('.menu-toggler-btn').toggleClass('close-menu-btn');
        navMenuWraper.toggleClass('show-menu');

        //  add/remove  .header-stand-out  class to .main-navbar when menu-toogler-clicked

        //  if the menu is opened
        if ($(".show-menu").length) {
            // add .header-stand-out class to .main-nav
            if (!pageHeader.hasClass(hdrStandOut))
                $(pageHeader).addClass(hdrStandOut);
        } else {
            // remove .header-stand-out class to .main-nav in case the window scrolltop less than 50px
            if (pageHeader.hasClass(hdrStandOut) && main_window.scrollTop() < 50 && main_window.innerWidth > '991px')
                $(pageHeader).removeClass(hdrStandOut);

        }

    })


    //showing navbar sub-menus
    hasSubMenu.on("click", function (e) {
        e.stopPropagation()
        if (!(main_window.innerWidth() > 1199)) {
            $(this).children('.dropdown-menu').slideToggle();
        }
    });



    // Start Smooth Scrolling To Window Top When Clicking on Back To Top Button
    $(backToTopButton).on('click', function () {
        root.animate({
            scrollTop: 0
        }, 1000);
    });
    // End Smooth Scrolling To Window Top When Clicking on Back To Top Button


    // Start tabs navigation 


    // Start Regular Tabs
    $(tabLink).on('click', function () {
        var target = $(this).attr('data-target');
        $(this).addClass('active').siblings().removeClass('active');
        $(target).addClass('visibale-tab').siblings('.tab-content').removeClass("visibale-tab");
        adjust_tabLink_B_line();
    });
    //End Regular  Tabs



    //End tabs navigation
    /* ----------------- End onClick Actions ----------------- */




    /* ----------------- Start onScroll Actions ----------------- */

    main_window.on('scroll', function () {

        if ($(this).scrollTop() > 50) {
            //show back to top btn
            backToTopButton.addClass("show");

        } else {
            //hide back to top btn
            backToTopButton.removeClass("show");
        }

        // to add/remove a class that makes navbar stands out 
        // by changing its colors to the opposit colors
        if ($(this).innerWidth() > 991) {

            if ($(this).scrollTop() > 50) {
                if (!$(pageHeader).hasClass(hdrStandOut))
                    $(pageHeader).addClass(hdrStandOut);
            } else {

                if ($(pageHeader).hasClass(hdrStandOut))
                    $(pageHeader).removeClass(hdrStandOut);
            }

        } else {

            // on screens smaller than 992px always add header-standout class
            if (!$(pageHeader).hasClass(hdrStandOut)) {
                $(pageHeader).addClass(hdrStandOut);
            }
        }

    });
    /* ----------------- End onScroll Actions ----------------- */



    /* ----------------- Start Window Resize Actions ----------------- */

    main_window.on('resize', function () {

        // a fix to make sure vigas slider always taking the full height of the hero section
        // if (heroVegasSlider.length) {
        //     if (pageHeroHeight < ($(this).innerHeight())) {
        //         $(pageHero).css('height', '100vh');

        //     }
        // }

        if (main_window.innerWidth() > 991) {
            if (navMenuWraper.hasClass('show-menu')) {
                navMenuWraper.removeClass('show-menu');
                $('.menu-toggler-btn').toggleClass('close-menu-btn');
            }

            if (hasSubMenu.children('.dropdown-menu').css("display", "none")) {
                hasSubMenu.children('.dropdown-menu').css("display", "block")
            };

        } else {
            if (hasSubMenu.children('.dropdown-menu').css("display", "block")) {
                hasSubMenu.children('.dropdown-menu').css("display", "none")
            };
        }


        adjust_tabLink_B_line();


    });

    /* ----------------- End Window Resize Actions ----------------- */



    /* --------------------------
    Start Vendors plugins options  
    ----------------------------*/

    /* Start Swiper Options */

    
   
    //initialize swiper [Hero Section] fade slider
    if ($('.hero-swiper-slider.slider-fade .swiper-container').length) {
        var heroSlider = new Swiper('.hero-swiper-slider.slider-fade .swiper-container', {
            speed: 1000,
            spaceBetween: 30,
            loop: true,
            reverseDirection: true,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },      
            // effect: 'coverflow',
            // coverflowEffect: {
            //     stretch: 100,
            // },
            autoplay: {
                delay: 5000,
                disableOnInteraction: true,
            },
            pagination: {
                el: '.hero-swiper-slider.slider-fade .swiper-pagination',
                type: 'fraction',
            },
            navigation: {
                nextEl: '.hero-swiper-slider.slider-fade .swiper-button-next',
                prevEl: '.hero-swiper-slider.slider-fade .swiper-button-prev',
            },
        });
    };

    // initialize swiper [Testimonials with ONE Column]
    if ($('.testimonials-1-col  .swiper-container').length) {
        var testimonialsSlider_1 = new Swiper('.testimonials-1-col  .swiper-container', {
            // Optional parameters
            speed: 500,
            loop: true,
            grabCursor: true,
            slidesPerView: 1,
            spaceBetween: 50,            
            autoHeight: true,
            delay: 5000,      
            breakpoints: {
                991: {
                    slidesPerView: 1
                }
            },
            navigation: {
                nextEl: '.testimonials-1-col .swiper-button-next',
                prevEl: '.testimonials-1-col .swiper-button-prev',
            },
            on: {
                resize: function () {
                    this.update();
                }
            },
        });
    }
});


/*----------------- Start page loading Actions -----------------  */

$(window).on("load", function () {
    
    //loading screen
    $("#loading-screen").fadeOut(500);
    $("#loading-screen").remove();
});

/*----------------- End Loading Event functions -----------------*/