import $ from "jquery";

function addClasslictToHeaderButton(){
    let callButton = document.querySelector('.page-header li[class*="sg-popup"]');
    callButton.classList.add('callButton');
    let callButtonLink = callButton.querySelector('a');
    callButtonLink.classList.remove('nav-link');
    callButtonLink.classList.add('wp-block-button__link');
}
addClasslictToHeaderButton();


// quiz
$(function() {

    let quiz = document.getElementById('quiz');
    let priceDiv;
    let priceResult = 0;
    let areaOfBuildInput = document.getElementById('area_of_build');
    let heightOfFloorInput = document.getElementById('height_of_floor');

    let arrayOfPriceDivs = document.querySelectorAll('h2.price-div');
    let inputPriceResult = document.querySelector('input[name="price_result"]');

    let arrayOfInputsTypeOfStyle = document.querySelectorAll('input[type="checkbox"][name="type_of_style"]');
    let arrayOfInputsTypeOfBuild = document.querySelectorAll('[name="type_of_build"]');
    let arrayOfInputsPartOfProjectActive = document.querySelectorAll('input[type="checkbox"][data-field-name="part_of_project_active"]');
    let arrayOfInputsPartOfProject = document.querySelectorAll('input[type="checkbox"][data-field-name="part_of_project"]');

    let arrayOfTypeOfStyleCheckboxes = new Array;
    let arrayOfPartOfProjectCheckboxes = new Array;
    let arrayOfPartOfProjectPrice = new Array;
    
    const maxheightOfFloor = Number(heightOfFloorInput.max);
    const percentRiceheightOfFloor = Number(heightOfFloorInput.step);

    heightOfFloorInput.removeAttribute('max');
    heightOfFloorInput.removeAttribute('step');

    let answers = {
        typeOfStyleCheckBox: arrayOfTypeOfStyleCheckboxes,
        answerTypeOfBuild: null,
        answerPriceOfBuild: null,
        areaOfBuildInput: null,
        heightOfFloorInput: null,
        partOfprojectInput: arrayOfPartOfProjectCheckboxes,
        partOfProjectPrice: arrayOfPartOfProjectPrice
    }

    function slideQuestions() {
        let arrayOfNextButtons = document.querySelectorAll('.quiz .quiz-button-next');
        let arrayOfPrevButtons = document.querySelectorAll('.quiz .quiz-button-prev');
        let arrayOfQuestions = document.querySelectorAll('.quiz .question');
        
        arrayOfNextButtons.forEach(function(nextButton, index) {
            nextButton.onclick = function() {
                arrayOfQuestions[index].classList.add('d-none');
                arrayOfQuestions[index+1].classList.remove('d-none');
                quiz.scrollIntoView({
                     block: "start",
                     behavior: "smooth"
                });
            }
        });
    
        arrayOfPrevButtons.forEach(function(nextButton, index) {
            nextButton.onclick = function() {
                arrayOfQuestions[index+1].classList.add('d-none');
                arrayOfQuestions[index].classList.remove('d-none');
                quiz.scrollIntoView({
                    block: "start",
                    behavior: "smooth"
                });            
            }
        });
    }

    slideQuestions();
    
    function addDataToObjectAnswers() {
        // выбор стиля и запись результата в объект

        let arrayOfimagesOfFirstQuestion = document.querySelectorAll('.first-question .wp-block-image');
        

        for (let imageOfFirstQuestion of arrayOfimagesOfFirstQuestion){
            let parentOfimage = imageOfFirstQuestion.parentNode;
            let inputCheckbox = parentOfimage.lastElementChild.querySelector('input');
            parentOfimage.classList.add('cursor-pointer');
            
            parentOfimage.onclick = function() {
                if (inputCheckbox.checked) {
                    let indexOfanswer = arrayOfTypeOfStyleCheckboxes.indexOf(inputCheckbox.value, 0);
                    arrayOfTypeOfStyleCheckboxes.splice(indexOfanswer, 1);
                    inputCheckbox.checked = false;

                    if (arrayOfTypeOfStyleCheckboxes.length === 0) {
                        let questionNext1 = document.getElementById('question-next-1');
                        questionNext1.classList.add('disabled');
                    }
                    return;
                }
                if (!inputCheckbox.checked) {
                    inputCheckbox.checked = true;
                    arrayOfTypeOfStyleCheckboxes.push(inputCheckbox.value);

                    if (arrayOfTypeOfStyleCheckboxes.length != 0) {
                        let questionNext1 = document.getElementById('question-next-1');
                        questionNext1.classList.remove('disabled');
                    }
                } 
            }
        } 
        
        for (let typeOfStyleCheckBox of arrayOfInputsTypeOfStyle){
            typeOfStyleCheckBox.onchange = function(event) {
                if (event.target.checked) {
                    arrayOfTypeOfStyleCheckboxes.push(typeOfStyleCheckBox.value);
                }
                if (!event.target.checked) {
                    let indexOfanswer = arrayOfTypeOfStyleCheckboxes.indexOf(typeOfStyleCheckBox.value, 0);
                    arrayOfTypeOfStyleCheckboxes.splice(indexOfanswer, 1);
                }
            }
        }

        // выбор типа помещения
        for (let typeOfBuildInputRadio of arrayOfInputsTypeOfBuild){
            typeOfBuildInputRadio.onchange = function(event) {
                if (event.target.checked) {
                    answers.answerTypeOfBuild = typeOfBuildInputRadio.value;
                    answers.answerPriceOfBuild = typeOfBuildInputRadio.dataset.calculate;
                }
            }
        }

        // добавление площади помещения
        areaOfBuildInput.onchange = function () {
            answers.areaOfBuildInput = areaOfBuildInput.value;
        }

        // добавление высоты помещения
        heightOfFloorInput.onchange = function () {
            answers.heightOfFloorInput = heightOfFloorInput.value;
        }
        
        // выбор стиля и запись результата в объект
        for (let partOfprojectInputCheckbox of arrayOfInputsPartOfProjectActive) {
            partOfprojectInputCheckbox.setAttribute('checked','checked');
            arrayOfPartOfProjectCheckboxes.push(partOfprojectInputCheckbox.value);
            arrayOfPartOfProjectPrice.push(partOfprojectInputCheckbox.dataset.calculate);

            partOfprojectInputCheckbox.onchange = function(event) {
                if (event.target.checked) {
                    arrayOfPartOfProjectCheckboxes.push(partOfprojectInputCheckbox.value);
                    arrayOfPartOfProjectPrice.push(partOfprojectInputCheckbox.dataset.calculate);
                }
                if (!event.target.checked) {
                    let indexOfanswer = arrayOfPartOfProjectCheckboxes.indexOf(partOfprojectInputCheckbox.value, 0);
                    arrayOfPartOfProjectCheckboxes.splice(indexOfanswer, 1);

                    let indexOfanswerPrice = arrayOfPartOfProjectPrice.indexOf(partOfprojectInputCheckbox.dataset.calculate, 0);
                    arrayOfPartOfProjectPrice.splice(indexOfanswerPrice, 1);
                }
            }
        }

        for (let partOfprojectInputCheckbox of arrayOfInputsPartOfProject){

            partOfprojectInputCheckbox.onchange = function(event) {
                if (event.target.checked) {
                    arrayOfPartOfProjectCheckboxes.push(partOfprojectInputCheckbox.value);
                    arrayOfPartOfProjectPrice.push(partOfprojectInputCheckbox.dataset.calculate);
                }
                if (!event.target.checked) {
                    let indexOfanswer = arrayOfPartOfProjectCheckboxes.indexOf(partOfprojectInputCheckbox.value, 0);
                    arrayOfPartOfProjectCheckboxes.splice(indexOfanswer, 1);
                    
                    let indexOfanswerPrice = arrayOfPartOfProjectPrice.indexOf(partOfprojectInputCheckbox.dataset.calculate, 0);
                    arrayOfPartOfProjectPrice.splice(indexOfanswerPrice, 1);
                }
            }
        }
    }
    addDataToObjectAnswers();

    

    function calculatePriceResult() {

        quiz.onchange = function() {
            let typeOfStyleCheckBox = answers.typeOfStyleCheckBox;
            let answerTypeOfBuild = answers.answerTypeOfBuild;
            let answerPriceOfBuild = Number(answers.answerPriceOfBuild);
            let areaOfBuildInput = Number(answers.areaOfBuildInput);
            let heightOfFloorInput = Number(answers.heightOfFloorInput);
            let partOfprojectInput = answers.partOfprojectInput;
            let partOfProjectPrice = answers.partOfProjectPrice.map(item => Number(item));

            let percentSum = 0;
            function calculetePercentSum() {
                for (let i = 0; i < partOfProjectPrice.length; i++) {
                    percentSum += partOfProjectPrice[i];
                }
                return percentSum
            }
            calculetePercentSum();

            function calculateAndAddPriceResult() {
                if (answerPriceOfBuild != 0 && areaOfBuildInput === 0) {
                    priceResult = answerPriceOfBuild;
                    for (priceDiv of arrayOfPriceDivs) {
                        priceDiv.innerHTML = priceResult + " руб./м<sup>2</sup>";
                    }
                    return
                }


                if (areaOfBuildInput != 0) {
                    priceResult = areaOfBuildInput * answerPriceOfBuild;
                    priceResult.toFixed();
                }

                if (heightOfFloorInput > maxheightOfFloor) {
                    priceResult = priceResult * (percentRiceheightOfFloor / 100);            
                    priceResult.toFixed();
                }

                priceResult = priceResult * percentSum / 100;

                for (priceDiv of arrayOfPriceDivs) {
                    priceResult.toFixed();
                    priceDiv.innerHTML = priceResult + " руб.";
                }

                priceResult.toFixed();
                inputPriceResult.value = priceResult;
            }
            calculateAndAddPriceResult();

            function lockAndUnlockDisableButtons() {
                let questionNext1 = document.getElementById('question-next-1');
                let questionNext2 = document.getElementById('question-next-2');
                let questionNext3 = document.getElementById('question-next-3');
        
                if (typeOfStyleCheckBox.length != 0) {
                    questionNext1.classList.remove('disabled');
                }

                if (answerTypeOfBuild != null) {
                    questionNext2.classList.remove('disabled');
                }

                if (areaOfBuildInput != 0 && heightOfFloorInput != 0) {
                    questionNext3.classList.remove('disabled');
                }
            }
            lockAndUnlockDisableButtons();

            function addResultsToDocument() {
                
                let typeOfStyleResultDiv = document.getElementById('type-of-style-result');
                let typeOfBuildResultDiv = document.getElementById('type-of-build-result');
                let areaOfBuildResultDiv = document.getElementById('area-of-build-result');
                let heightOfBuildResultDiv = document.getElementById('height-of-build-result');
                let partOfBuildResultDiv = document.getElementById('part-of-project-result');

                let ulPartOfBuild = '<ul>';
                partOfprojectInput.forEach(function(item, i, arr) {
                    ulPartOfBuild += '<li>'+item+'</li>';
                });
                ulPartOfBuild += '</ul>'
                partOfBuildResultDiv.innerHTML = ulPartOfBuild;

                let ulTypeOfStyle = '<ul>';
                typeOfStyleCheckBox.forEach(function(item, i, arr) {
                    ulTypeOfStyle += '<li>'+item+'</li>';
                });
                ulTypeOfStyle += '</ul>'
                
                typeOfStyleResultDiv.innerHTML = ulTypeOfStyle;
                typeOfBuildResultDiv.innerHTML = answerTypeOfBuild;
                areaOfBuildResultDiv.innerHTML = areaOfBuildInput + " м<sup>2<sup>";
                heightOfBuildResultDiv.innerHTML = heightOfFloorInput + " см";
            }
            addResultsToDocument();
        }
    }

    calculatePriceResult();
});
